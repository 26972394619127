<template>
  <div
    class="provider-booking-table user-service-calender app-calendar overflow-hidden"
    style="border-top-left-radius: 0"
  >
    <div class="row">
      <div class="col-md-8">
        <div class="position-relative">
          <div class="card shadow-none border-0 mb-0 rounded-0">
            <div class="card-body pb-0">
              <full-calendar
                ref="refCalendar"
                :options="calendarOptions"
                class="full-calendar"
              />
              
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <h4>{{ $t('Create Bookings') }}</h4>
        <order-creation
          ref="orderForm"
          :is-booking-page="true"
          @create-order="orderCreated"
        />
      </div>
    </div>
    <b-modal
      id="updateRequestModal"
      v-model="serviceHistoryProps.viewUpdateRequestModal"
      :no-close-on-backdrop="true"
      :title="$t('Update Request')"
      @hidden="hideUpdateRequestModal"
      @ok="updateRequestStatus"
    >
      <div class="wrap-update-request">
        <div class="col-md-12">
          <b-form-group
            v-if="serviceHistoryProps.updateRequest.selectedStatus === 'SCHEDULED'"
            :label="$t('Schedule time')"
          >
            <custom-calender
              ref="calenderRef"
              :hide-submit="true"
              :timing="storeCurrentTimings"
              :all-timings="providerTimings"
              :store-slot-info="providerTimings"
            />
          </b-form-group>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="upcomingServiceModal"
      v-model="serviceHistoryProps.upcomingServiceDetailVisible"
      :no-close-on-backdrop="true"
      :title="$t('Service Details')"
      :hide-footer="true"
      size="lg"
      @hidden="hideUpcomingServiceModal"
    >
      <view-service
        v-if="serviceHistoryProps.tripHistoryObject"
        :show-re-sechdule="true"
        :service-history-props="{ tripHistoryObject: serviceHistoryProps.tripHistoryObject }"
        @request-schedule="rescheduleRequest"
      />
      <no-data-available
        v-else
        :type="'product'"
      />
    </b-modal>
  </div>
</template>

<script>
import { BFormGroup, BModal } from 'bootstrap-vue'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import moment from 'moment'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import { hideLoader, showDangerNotification, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import eventBus from '@/libs/event-bus'
import OrderCreation from './shared/OrderCreation.vue'
import ViewService from './my-services/ViewService.vue'
import { useProviderUi } from './useProvider'
import CustomCalender from '../users/shared/CustomCalender.vue'
import { useUserUi } from '../users/useUser'

export default {
  components: {
    BFormGroup,
    FullCalendar,
    OrderCreation,
    BModal,
    CustomCalender,
    ViewService,
  },
  data() {
    return {
      paymentInfo: {
        couponCode: {},
        isPromocodeApplied: false,
      },
      promocodeList: [],
      serviceHistoryProps: {
        upcomingServiceDetailVisible: false,
        tripHistoryObject: null,
        originalObject: null,
        viewUpdateRequestModal: false,
        updateRequest: {
          selectedStatus: '',
        },
      },
      isPromocodeVisible: false,
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
        initialView: 'dayGridMonth',
        headerToolbar: {
          start: 'sidebarToggle, prev,next, title',
          end: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
        },
        dragScroll: true,
        dayMaxEvents: 2,
        navLinks: true,
        eventClassNames({ event: calendarEvent }) {
          const calendarsColor = {
            AtStore: 'primary',
            AtCustomer: 'success',
          }
          // eslint-disable-next-line no-underscore-dangle
          const colorName = calendarsColor[calendarEvent._def.extendedProps.calendar]

          return [`bg-light-${colorName}`]
        },
        rerenderDelay: 350,
        events: [],
        // eslint-disable-next-line no-unused-vars
        eventClick(data, event, view) {
          window.providerBookings.viewServiceHistory(data.event.extendedProps.payload)
        },
      },
      paymentMode: 'CASH',
      optionsLabel: [
        {
          label: 'Cash',
          value: 'CASH',
        },
        {
          label: 'Card',
          value: 'CARD',
        },
        {
          label: 'Card on Delivery',
          value: 'CARD_ON_DELIVERY',
        },
      ],
      bookedTimeSlotData: {},
      calendarsColor: {
        AtStore: 'primary',
        AtCustomer: 'success',
      },
      providerUserAddress: {
        provider_address: {},
        user_address: {},
      },
      bookedTimeSlots: [],
      activeAddress: {
        user: true,
        provider: true,
      },
    }
  },
  computed: {
    storeTimings() {
      if (!this.providerTimings) return null
      const currentDay = moment().format('ddd').toUpperCase()
      return this.providerTimings.find(x => x.store_day === currentDay)
    },
  },
  mounted() {
    window.providerBookings = this
    this.getTimeSlots()
    this.getProviderTimings()
    document.addEventListener('click', this.documentClicked)
  },
  destroyed() {
    document.removeEventListener('click', this.documentClicked)
  },
  methods: {
    moment,
    storeCurrentTimingsInfo() {
      if (!this.providerTimings) return null
      if (this.storeTimings) return this.storeTimings
      return this.providerTimings[0]
    },
    getProviderTimings() {
      const { getProviderTimings } = useUserUi()
      const { slug } = window.SuperApp.getters.userInfo()
      getProviderTimings(`?provider_id=${slug}`)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.providerTimings = data.responseData
            this.storeCurrentTimings = this.storeCurrentTimingsInfo()
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    documentClicked(event) {
      if (event && event.target && event.target.parentElement && event.target.parentElement.className === 'fc-button-group') {
        if (event.target.innerText === 'Week') {
          const groups = window.SuperApp.actions.groupArray(this.calendarOptions.events, x => new Date(x.date).toLocaleDateString())
          let newGroup = []
          groups.forEach(item => {
            item.forEach(x => {
              // eslint-disable-next-line no-param-reassign
              x.allDay = false
            })
            if (item.length > 3) {
              item.forEach(x => {
                // eslint-disable-next-line no-param-reassign
                x.allDay = true
              })
            }
            newGroup = [...item, ...newGroup]
          })
          this.calendarOptions.events = newGroup
        } else {
          this.calendarOptions.events = this.calendarOptions.events.map(x => ({
            ...x,
            allDay: false,
          }))
        }
      }
    },
    rescheduleRequest() {
      this.serviceHistoryProps.viewUpdateRequestModal = true
      this.serviceHistoryProps.updateRequest.selectedStatus = 'SCHEDULED'
    },
    hideUpdateRequestModal() {
      this.serviceHistoryProps.viewUpdateRequestModal = false
    },
    viewServiceHistory(props) {
      this.serviceHistoryProps.originalObject = props
      if (!this.serviceHistoryProps.originalObject.service_request_id) {
        showDangerNotification(this, 'No service found for this record!')
        return
      }
      this.getTripHistory()
    },
    updateRequestStatus() {
      const formData = new FormData()
      let date = ''
      let year = ''
      let time = ''
      let month = ''
      let day = ''
      if (this.$refs.calenderRef) {
        this.serviceHistoryProps.updateRequest.scheduleDate = new Date(`${this.$refs.calenderRef.$data.activeDate.toDateString()} ${this.$refs.calenderRef.$data.activeTimeSlot}`)
      }
      if (this.serviceHistoryProps.updateRequest.scheduleDate) {
        const splitDate = this.serviceHistoryProps.updateRequest.scheduleDate
        date = splitDate
        year = new Date(date).getFullYear()
        month = new Date(date).getMonth() + 1
        day = new Date(date).getDate()
        date = `${new Date(date).getMonth() + 1}/${new Date(date).getDate()}/${new Date(date).getFullYear()}`
        if (this.$refs.calenderRef) {
          time = this.$refs.calenderRef.$data.activeTimeSlot
        }
      }
      const updateRequestDTO = this.serviceHistoryProps.originalObject
      formData.append('id', updateRequestDTO.service_request_id)
      formData.append('user_id', updateRequestDTO.user_id)
      formData.append('provider_id', updateRequestDTO.provider_id)
      formData.append('status', this.serviceHistoryProps.updateRequest.selectedStatus)
      formData.append('agent_id', this.serviceHistoryProps.updateRequest.assignedAgent)
      formData.append('date', date)
      formData.append('time', time)
      formData.append('year', year)
      formData.append('day', day)
      formData.append('month', month)
      formData.append('comments', this.serviceHistoryProps.updateRequest.notes)
      formData.append('admin_service', 'SERVICE')
      showLoader()
      const { updateProviderRequest } = useProviderUi()
      const requestUrl = 'provider/service/reschedule'
      updateProviderRequest({
        query: requestUrl,
        data: formData,
      })
        .then(({ data }) => {
          if (data.statusCode === '200') {
            showSuccessNotification(this, 'Request updated successfully')
            if (window.SuperApp.getters.isProvider()) {
              eventBus.$emit('user-sync-app', {
                userId: updateRequestDTO.user_id,
                serviceRescheduled: true,
                providerName: `${window.SuperApp.getters.userInfo().first_name} ${window.SuperApp.getters.userInfo().last_name}`,
                invoiceId: updateRequestDTO.booking_id,
              })
            }
            this.getTimeSlots()
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    getTripHistory() {
      showLoader()
      const { providerRequestDetail } = useProviderUi()
      providerRequestDetail(this.serviceHistoryProps.originalObject.service_request_id)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.serviceHistoryProps.tripHistoryObject = data.responseData.service
            this.serviceHistoryProps.upcomingServiceDetailVisible = true
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    hideUpcomingServiceModal() {
      this.serviceHistoryProps.upcomingServiceDetailVisible = false
    },
    bookTimeSlot(info) {
      console.log(info)
    },
    orderCreated() {
      this.getTimeSlots()
      this.$refs.orderForm.resetForm()
    },
    getServiceName(serviceType) {
      if (serviceType && serviceType[0]) {
        const payload = serviceType[0]
        if (
          payload
          && payload.items
          && payload.items[0]
          && payload.items[0].provider_service
          && payload.items[0].provider_service.main_service
          && payload.items[0].provider_service.main_service.service_category
        ) {
          return payload.items[0].provider_service.main_service.service_category.alias_name
        }
      }
      return 'NA'
    },
    getBookingDetails(serviceType) {
      if (serviceType && serviceType[0]) {
        return `. Service Id: ${serviceType[0].booking_id}`
      }
      return ''
    },
    getTimeSlots() {
      const { bookedTimeSlots } = useProviderUi()
      showLoader()
      bookedTimeSlots('')
        .then(({ data }) => {
          if (data.statusCode === '200') {
            const events = data.responseData.map(x => ({
              title: `${this.getServiceName(x.requests)} Request${this.getBookingDetails(x.requests)}`,
              date: new Date(`${moment(new Date(x.booking_date)).format('YYYY-MM-DD')} ${x.time ? x.time : ''}`),
              payload: x,
            }))
            this.calendarOptions.events = events
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/apps/calendar.scss';

.provider-booking-table {
  .vue-phone-number-input {
    width: 100%;
  }

  .booking-page-phone {
    margin-top: 10px;
  }
}
</style>

<style scoped>
.service-checkout-right-section {
  background: rgb(247, 247, 247);
  padding-left: 0;
}

.service-checkout-card {
  box-shadow: rgb(34 41 47 / 10%) 0px 4px 24px 0px !important;
  margin-bottom: 15px;
  margin-right: 5px;
  margin-left: 5px;
}

.service-checkout-card table.table th,
.service-checkout-card table.table td {
  padding: 2px 1rem;
}

@media screen and (min-width: 768px) {
  .col-md-5-5 {
    flex: 0 0 47%;
    max-width: 47%;
  }
}

@media only screen and (max-width: 767px) {
  .responsive-margin-bottom-checkout {
    margin-bottom: 10px;
  }

  .service-checkout-right-section {
    background: #fff;
    padding-left: 1rem;
  }

  .service-checkout-right-section .card-body {
    padding-left: 8px;
    padding-right: 8px;
  }
}
</style>
